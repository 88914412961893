import DashboardIcon from "assets/icons/tachometer-alt-fast.svg";
import TherapistsIcon from "assets/icons/user-tie.svg";
import PatientsIcon from "assets/icons/users-medical.svg";
import SubscriptionsIcon from "assets/icons/credit-card.svg";
import TagsIcon from "assets/icons/tags.svg";
import PlansIcon from "assets/icons/layer-group.svg";
import LibraryIcon from "assets/icons/books.svg";
import PermissionIcon from "assets/icons/key.svg";
import RolesIcon from "assets/icons/id-card.svg";

export const navItems = [
  {
    label: "Dashboard",
    icon: DashboardIcon,
    link: "/dashboard",
  },
  {
    label: "Therapists",
    icon: TherapistsIcon,
    link: "/therapists",
  },
  {
    label: "Patients",
    icon: PatientsIcon,
    link: "/patients",
  },
  {
    label: "Subscriptions",
    icon: SubscriptionsIcon,
    link: "/subscriptions",
  },
  {
    label: "Plans",
    icon: PlansIcon,
    link: "/plans",
  },
  {
    label: "Template Tags",
    icon: TagsIcon,
    link: "/template-tags",
  },
  {
    label: "Library Resources",
    icon: LibraryIcon,
    link: "/library-resources",
  },
  {
    label: "Permissions",
    icon: PermissionIcon,
    link: "/permissions",
  },
  {
    label: "Roles",
    icon: RolesIcon,
    link: "/roles",
  },
];
