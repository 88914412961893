import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";
import { updateRoleAction } from "store/actions/roles";
import useFetchRoles from "./useFetchRoles";

const useUpdateRole = ({ roleId }) => {
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchAllRoles } = useFetchRoles();

  const submitUpdateRole = (args) => {
    if (!args) {
      return null;
    }

    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
              mutation {
                updateRole(role: {
                  uuid: "${roleId}",
                  name: "${args.name}",
                  description: "${args.description}"
                }) {
                  uuid
                  name
                  description
                  createdAt
                  updatedAt
                }
              }
            `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(
                `${error.errors[0].message || "Hmm, something went wrong."}`,
              );
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`,
            );
            return true;
          } else {
            fetchAllRoles();
            toast("Role updated successfully!");
            setIsUpdateSuccessful(true);
            return dispatch(updateRoleAction(resData.data.updateRole));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    submitUpdateRole,
    isUpdateSuccessful,
  };
};

export default useUpdateRole;
