export const fetchTherapistsAction = (therapists) => {
  return {
    type: "FETCH_THERAPISTS",
    payload: therapists,
  };
};

export const updateTherapistVerificationAction = (therapistId) => {
  return {
    type: "VERIFY_THERAPIST",
    payload: therapistId,
  };
};
