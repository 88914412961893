export const fetchRolesAction = (roles) => {
  return {
    type: "FETCH_ROLES",
    payload: roles,
  };
};

export const createRoleAction = (role) => {
  return {
    type: "CREATE_ROLE",
    payload: role,
  };
};

export const updateRoleAction = (role) => {
  return {
    type: "UPDATE_ROLE",
    payload: role,
  };
};

export const updateRolesAction = (roles) => {
  return {
    type: "UPDATE_ROLES",
    payload: roles,
  };
};

export const deleteRoleAction = (role) => {
  return {
    type: "DELETE_ROLE",
    payload: role,
  };
};
