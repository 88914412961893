import { useSelector } from "react-redux";

const useGetPatients = ({ slug, id }) => {
  const patients = useSelector((state) => state.patients && state.patients);

  if (!patients) {
    return [];
  }

  return {
    patients: patients.patients,
  };
};

export default useGetPatients;
