import "./PageTitle.styles.scss";
import PropTypes from "prop-types";

const PageTitle = ({ text, ...props }) => {
  return (
    <div className="page-title__wrapper">
      <h3 className="page-title">{text}</h3>
      {props.children}
    </div>
  );
};

PageTitle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PageTitle;
