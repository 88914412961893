import "./AppLayout.styles.scss";
import { useEffect } from "react";
import { MobileBottomNav, SideNav } from "components";
import { useViewport, useGuestRedirect } from "utils/hooks";

const AppLayout = (props) => {
  const { redirect } = useGuestRedirect();
  const { isMobile } = useViewport();
  useEffect(() => {
    redirect();
  }, [redirect]);

  return (
    <div className="flex">
      {isMobile ? <MobileBottomNav /> : <SideNav />}
      <div className="flex col app-body">
        <div className="app-content">{props.children}</div>
      </div>
    </div>
  );
};

export default AppLayout;
