import ThoughtIcon from "assets/icons/waveform-path.svg";
import MoodIcon from "assets/icons/cloud-rainbow.svg";
import JournalIcon from "assets/icons/book-spells.svg";

export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME;

export const ASSIGNMENT_TYPES = [
  {
    type: "THOUGHT_RECORD",
    title: "Thought Record",
    icon: ThoughtIcon,
  },
  {
    type: "MOOD_TRACKER",
    title: "Mood Tracker",
    icon: MoodIcon,
  },
  {
    type: "JOURNAL",
    title: "Journal",
    icon: JournalIcon,
  },
];

export const BREAKPOINTS = {
  viewport4: "480",
  viewport7: "768",
  viewport9: "992",
  viewport12: "1200",
};
