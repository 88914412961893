import { useSelector } from "react-redux";

const useGetSubmissions = ({ slug, id }) => {
  const submissions = useSelector(
    (state) => state.submissions && state.submissions,
  );

  if (!submissions) {
    return [];
  }

  return {
    submissions: submissions.submissions,
  };
};

export default useGetSubmissions;
