import "./Logo.styles.scss";
import PropTypes from "prop-types";
import { BRAND_NAME } from "utils/constants";
import LogoIcon from "assets/icons/castle.svg";
import Icon from "components/utility/Icon";

const Logo = ({ theme, showIcon }) => {
  return (
    <div className={`logo ${theme}`}>
      {showIcon && (
        <div className="logo__icon">
          <Icon src={LogoIcon} />
        </div>
      )}
      <p className="logo__text">{BRAND_NAME}</p>
    </div>
  );
};

Logo.propTypes = {
  theme: PropTypes.string,
  showIcon: PropTypes.bool,
};

Logo.defaultProps = {
  theme: "light",
  showIcon: true,
};

export default Logo;
