import { PURGE } from "redux-persist";

const initialState = {
  roles: [],
};

const roles = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_ROLES":
      return {
        ...state,
        roles: payload,
      };
    case "CREATE_ROLE":
      return {
        ...state,
        roles: [...state.roles, payload],
      };
    case "UPDATE_ROLE":
      return {
        ...state,
        roles: state.roles.map((role) =>
          role.uuid === payload.uuid
            ? {
                ...role,
                ...payload,
              }
            : role,
        ),
      };
    case "UPDATE_ROLES":
      return {
        ...state,
        roles: state.roles.map((role) => {
          const updatedRole = payload.find((r) => r.uuid === role.uuid);
          return updatedRole ? { ...role, ...updatedRole } : role;
        }),
      };
    case "DELETE_ROLE":
      return {
        ...state,
        roles: state.roles.filter((role) => role.uuid !== payload.uuid),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default roles;
