import { PURGE } from "redux-persist";

const initialState = {
  users: [],
};

const users = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_ALL_USERS":
      return {
        ...state,
        users: [...payload],
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
export default users;
