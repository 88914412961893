import { PURGE } from "redux-persist";

const initialState = {
  plans: [],
};

const plans = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PLANS":
      return {
        ...state,
        plans: payload,
      };
    case "CREATE_PLAN":
      return {
        ...state,
        plans: [...state.plans, payload],
      };
    case "UPDATE_PLAN":
      return {
        ...state,
        plans: state.plans.map((sub) =>
          sub.uuid === payload.uuid
            ? {
                ...sub,
                ...payload,
              }
            : sub,
        ),
      };
    case "DELETE_PLAN":
      return {
        ...state,
        plans: state.plans.filter((plan) => plan.uuid !== payload.uuid),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default plans;
