import { PURGE } from "redux-persist";

const initialState = {
  permissions: [],
};

const permissions = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PERMISSIONS":
      return {
        ...state,
        permissions: payload,
      };
    case "CREATE_PERMISSION":
      return {
        ...state,
        permissions: [...state.permissions, payload],
      };
    case "UPDATE_PERMISSION":
      return {
        ...state,
        permissions: state.permissions.map((perm) =>
          perm.uuid === payload.uuid
            ? {
                ...perm,
                ...payload,
              }
            : perm,
        ),
      };
    case "DELETE_PERMISSION":
      return {
        ...state,
        permissions: state.permissions.filter((perm) => perm.uuid !== payload),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default permissions;
