export const fetchTemplateTagsAction = (templateTags) => {
  return {
    type: "FETCH_TEMPLATE_TAGS",
    payload: templateTags,
  };
};

export const createTemplateTagAction = (templateTag) => {
  return {
    type: "CREATE_TEMPLATE_TAG",
    payload: templateTag,
  };
};

export const updateTemplateTagAction = (templateTag) => {
  return {
    type: "UPDATE_TEMPLATE_TAG",
    payload: templateTag,
  };
};

export const deleteTemplateTagAction = (templateTag) => {
  return {
    type: "DELETE_TEMPLATE_TAG",
    payload: templateTag,
  };
};
