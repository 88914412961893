import { useSelector } from "react-redux";

const useGetNotes = () => {
  const notes = useSelector((state) => state.notes && state.notes);

  if (!notes) {
    return null;
  }

  return {
    notes: {
      total: notes.notes.total,
      averagePerTherapist: notes.notes.averagePerTherapist,
    },
  };
};

export default useGetNotes;
