import "./SubmissionStatsCard.styles.scss";
import { Icon, ImageSeparator } from "components";
import ArrowRight from "assets/icons/arrow-right.svg";
import SubmissionIcon from "assets/icons/inbox.svg";

const SubmissionStatsCard = ({ data, title }) => {
  const { total, averagePerPatient, averagePerAssignment } = data;

  return (
    <div className="submission-stats-card">
      <div className="submission-stats-card_section">
        <div className="submission-stats-card_section-title">
          <div className="submission-stats-card_icon--wrapper">
            <div className="submission-stats-card_icon">
              <Icon src={SubmissionIcon} alt={`${title} icon`} />
            </div>
          </div>
          <div className="submission-stats-card_main-title">
            Submission Stats
          </div>
        </div>
        <div className="submission-stats-card_data">
          <p className="submission-stats-card_count">{total}</p>
          <p className="submission-stats-card_title">
            {total > 1 || total === 0 ? title : title.slice(0, -1)}
          </p>
        </div>
      </div>
      <ImageSeparator />
      <div className="submission-stats-card_table">
        <div className="submission-stats-card_column">
          <p className="submission-stats-card_count">
            {Math.round(averagePerPatient)}
          </p>
          <p className="submission-stats-card_title">Avg # per Patient</p>
        </div>
        <div className="submission-stats-card_column">
          <p className="submission-stats-card_count">
            {Math.round(averagePerAssignment)}
          </p>
          <p className="submission-stats-card_title">Avg # per Assignment</p>
        </div>
      </div>
      <div id="statsCardRight">
        <div className="submission-stats-card__nav-arrow">
          <Icon src={ArrowRight} alt={`go to ${title} icon`} />
        </div>
      </div>
    </div>
  );
};

export default SubmissionStatsCard;
