import { PURGE } from "redux-persist";

const initialState = {
  templateTags: [],
};

const templateTags = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_TEMPLATE_TAGS":
      return {
        ...state,
        templateTags: payload,
      };
    case "CREATE_TEMPLATE_TAG":
      return {
        ...state,
        templateTags: [...state.templateTags, payload],
      };
    case "UPDATE_TEMPLATE_TAG":
      return {
        ...state,
        templateTags: state.templateTags.map((sub) =>
          sub.uuid === payload.uuid
            ? {
                ...sub,
                ...payload,
              }
            : sub,
        ),
      };
    case "DELETE_TEMPLATE_TAG":
      return {
        ...state,
        templateTags: state.templateTags.filter(
          (templateTag) => templateTag.uuid !== payload.uuid,
        ),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default templateTags;
