import "./ImageSeparator.styles.scss";

const ImageSeparator = ({ image, alt }) => {
  return (
    <div className="image-separator">
      <div className="image-separator__line" />
      <img className="image-separator__image" alt={alt} src={image} />
      <div className="image-separator__line" />
    </div>
  );
};

export default ImageSeparator;
