import { useSelector } from "react-redux";

const useGetSubscriptions = () => {
  const subscriptions = useSelector(
    (state) => state.subscriptions && state.subscriptions.subscriptions,
  );

  if (!subscriptions) {
    return null;
  }

  const formattedSubscriptions = () => {
    return subscriptions.map((s) => {
      return {
        label: s.name,
        value: s.uuid,
      };
    });
  };

  return {
    subscriptions: subscriptions,
    formattedSubscriptions: formattedSubscriptions,
  };
};

export default useGetSubscriptions;
