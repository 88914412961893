export const fetchPermissionsAction = (permissions) => {
  return {
    type: "FETCH_PERMISSIONS",
    payload: permissions,
  };
};

export const createPermissionAction = (permission) => {
  return {
    type: "CREATE_PERMISSION",
    payload: permission,
  };
};

export const updatePermissionAction = (permission) => {
  return {
    type: "UPDATE_PERMISSION",
    payload: permission,
  };
};

export const deletePermissionAction = (permissionId) => {
  return {
    type: "DELETE_PERMISSION",
    payload: permissionId,
  };
};
