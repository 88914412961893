import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  useFetchAssignments,
  useFetchUsers,
  useFetchSubmissions,
  useLogout,
} from "utils/hooks";
import setAuthenticatedUser from "store/actions/auth";

const useVerifyCode = () => {
  const dispatch = useDispatch();
  const { fetchAllAssignments } = useFetchAssignments();
  const { fetchAllUsers } = useFetchUsers();
  const { fetchAllSubmissions } = useFetchSubmissions();
  const { logOut } = useLogout();

  const verifyCode = async (args) => {
    const { user, code } = args;

    try {
      const res = await fetch(
        `${process.env.REACT_APP_REST_API_URL}/2fa/verify-code`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: user.email, code }),
        },
      );

      const resData = await res.json();

      if (res.ok) {
        dispatch(setAuthenticatedUser(user));
        fetchAllUsers();
        fetchAllSubmissions();
        fetchAllAssignments();
      }

      if (!res.ok) {
        if (resData.message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(resData.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    verifyCode,
  };
};

export default useVerifyCode;
