import "./styles.scss";
import PropTypes from "prop-types";

const AlertBar = ({ content, type }) => {
  return <div className={`alert-bar alert-bar-${type}`}>{content}</div>;
};

AlertBar.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]),
  type: PropTypes.string,
  dismissable: PropTypes.bool,
};

AlertBar.defaultProps = {
  type: "info",
  dismissable: true,
};

export default AlertBar;
