import { useSelector } from "react-redux";

const useGetResourcesData = () => {
  const resources = useSelector((state) => state.resources && state.resources);

  if (!resources) {
    return null;
  }

  return {
    resourcesData: {
      total: resources.resourcesData?.total,
      averagePerTherapist: resources.resourcesData?.averagePerTherapist,
    },
  };
};

export default useGetResourcesData;
