import "./Animate.styles.scss";
import PropTypes from "prop-types";

const Animate = ({ animation, delay, width, style, children }) => {
  return (
    <div
      className={`animate ${animation}`}
      style={{ animationDelay: `${delay}s`, width: width, ...style }}
    >
      {children}
    </div>
  );
};

Animate.propTypes = {
  animation: PropTypes.string,
  delay: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Animate.defaultProps = {
  animation: "fadeInDown",
  delay: "0",
  width: "auto",
};

export default Animate;
