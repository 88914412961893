import "./MobilePageHeader.styles.scss";
import { useViewport } from "utils/hooks";
import MobilePageHeaderAction from "./components/MobilePageHeaderAction";

const MobilePageHeader = ({
  title,
  rightAction,
  leftAction,
  rightIcon,
  leftIcon,
}) => {
  const { isMobile } = useViewport();
  if (!isMobile) {
    return false;
  }
  const dynamicJustification = () => {
    let justify = "start";
    if ((!leftAction && !rightAction) || (leftAction && !rightAction)) {
      justify = "start";
    }
    if ((!leftAction && rightAction) || (leftAction && rightAction)) {
      justify = "space-between";
    }
    return justify;
  };
  return (
    <div
      className="mobile-page-header"
      style={{ justifyContent: `${dynamicJustification()}` }}
    >
      {leftAction && (
        <div className="mobile-page-header__left">
          <MobilePageHeaderAction onActionClick={leftAction} icon={leftIcon} />
        </div>
      )}
      <h3 className="mobile-page-header__title">{title}</h3>
      {rightAction && (
        <div className="mobile-page-header__right">
          <MobilePageHeaderAction
            onActionClick={rightAction}
            icon={rightIcon}
          />
        </div>
      )}
    </div>
  );
};

export default MobilePageHeader;
