import { useSelector } from "react-redux";

const useGetTemplateTags = () => {
  const templateTags = useSelector(
    (state) => state.templateTags && state.templateTags.templateTags,
  );

  if (!templateTags) {
    return null;
  }

  return {
    templateTags: templateTags,
  };
};

export default useGetTemplateTags;
