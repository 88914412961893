import { format } from "date-fns";

export const snakeToCamel = (str) => {
  return str.toLowerCase().replace(/(_\w)/g, function (m) {
    return m[1].toUpperCase();
  });
};

export const toAmericanDate = (timestring) => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return null;
  }
  return format(new Date(timestring), "MM/dd/yyyy");
};

export const toAmericanDateFromUnix = (timestring) => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return null;
  }
  return format(Number(timestring), "MM/dd/yyyy");
};

export const toAmericanDateWithTimeFromUnix = (timestring) => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return null;
  }
  return format(Number(timestring), "MM/dd/yyyy h:mm a");
};

export const toTimeFromUnix = (timestring) => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return null;
  }
  return format(Number(timestring), "h:mm a");
};

export const toFullDateWithTimeFromUnix = (timestring) => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return null;
  }
  return format(Number(timestring), "E, LLLL d y h:mm a");
};

export const toReadableTime = (timestring) => {
  if (
    !timestring ||
    timestring === "null" ||
    timestring === undefined ||
    timestring === "undefined"
  ) {
    return null;
  }
  return format(new Date(timestring), "h:mm a");
};

export const obfuscateEmail = (emailAddress) => {
  const split = emailAddress.split("@");
  return (
    emailAddress.substr(0, 3) +
    new Array(split[0].length - 1).fill("*").join("") +
    "@" +
    split[1]
  );
};

export const obfuscateUUID = (uuid) => {
  if (!uuid) {
    return false;
  }
  const firstEight = uuid?.substring(0, 8);
  return `${firstEight}-****-****-****-************`;
};

export const escapeQuotes = (string) => {
  if (!string || string === "null" || string === "undefined") {
    return null;
  }
  return string.toString().replace(/"/g, '\\"');
};

export const formatInput = (string) => {
  const escapedQuotes = escapeQuotes(string);
  if (!escapedQuotes) {
    return "";
  }
  return escapedQuotes?.toString().replaceAll("\n", "\\n");
};
