import { PURGE } from "redux-persist";

const initialState = {
  subscriptions: [],
};

const subscriptions = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_SUBSCRIPTIONS":
      return {
        ...state,
        subscriptions: payload,
      };
    case "CREATE_SUBSCRIPTION":
      return {
        ...state,
        subscriptions: [...state.subscriptions, payload],
      };
    case "UPDATE_SUBSCRIPTION":
      return {
        ...state,
        subscriptions: state.subscriptions.map((sub) =>
          sub.uuid === payload.uuid
            ? {
                ...sub,
                ...payload,
              }
            : sub,
        ),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default subscriptions;
