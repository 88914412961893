/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import "./SideNavItem.styles.scss";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import { Icon } from "components";

const SideNavItem = ({ expanded, item }) => {
  const { label, icon, link, hasAlert } = item;
  return (
    <>
      <NavLink
        as="div"
        to={link}
        className={clsx("side-nav-item", expanded && "side-nav-item__expanded")}
        name={label}
        id={label}
        data-tip
        data-for={`${label.toLowerCase()}-tip`}
      >
        <div className="side-nav-item__icon">
          <Icon src={icon} />
        </div>
        <div className="side-nav-item__label">{label}</div>
        {hasAlert && <div className="side-nav-item__dot" />}
      </NavLink>
      {!expanded && (
        <ReactTooltip id={`${label.toLowerCase()}-tip`} place="right">
          <span>{label}</span>
        </ReactTooltip>
      )}
    </>
  );
};

SideNavItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }),
  expanded: PropTypes.bool,
  hasAlert: PropTypes.bool,
};

SideNavItem.defaultProps = {
  expanded: false,
  hasAlert: false,
};

export default SideNavItem;
