import { useState } from "react";
import { updateTemplateTagAction } from "store/actions/templateTags";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";
import useFetchTemplateTags from "./useFetchTemplateTags";

const useUpdateTemplateTag = ({ templateTagId }) => {
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchAllTemplateTags } = useFetchTemplateTags();

  const submitUpdateTemplateTag = (args) => {
    if (!args) {
      return null;
    }

    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
              mutation {
                updateAdminTemplateTag(templateTag: {
                  title: "${args.title}",
                  color: "${args.color.color || args.color}",
                  icon: "${args.icon}",
                  type: "${args.type.value}",
                  uuid: "${templateTagId}",
                }) {
                  title
                  color
                  icon
                  createdAt
                  updatedAt
                  uuid
                  type
                  authorId
                }
              }
            `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(
                `${error.errors[0].message || "Hmm, something went wrong."}`,
              );
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`,
            );
            return true;
          } else {
            fetchAllTemplateTags();
            toast("Awesome! Template Tag Updated!");
            setIsUpdateSuccessful(true);
            return dispatch(
              updateTemplateTagAction(resData.data.updateAdminTemplateTag),
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    submitUpdateTemplateTag,
    isUpdateSuccessful,
  };
};

export default useUpdateTemplateTag;
