import "./styles.scss";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { default as ReactSelect } from "react-select";
import clsx from "clsx";

const Select = ({
  options,
  name,
  label,
  error,
  forwardRef,
  forwardControl,
  hint,
  rules,
  defaultValue,
  value,
  menuPlacement,
  ...props
}) => {
  const { control } = useForm();
  return (
    <div className="select" id={name}>
      <label className="select__label">{label}</label>
      <Controller
        key={value}
        classNamePrefix="select"
        forwardRef={forwardRef}
        as={ReactSelect}
        options={options}
        name={name}
        control={forwardControl || control}
        aria-label={label}
        className={clsx("select__field", error && "error")}
        menuPortalTarget={document.getElementById(`'${name}'`)}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        rules={rules}
        defaultValue={defaultValue || value || null}
        value={value}
        menuPlacement={menuPlacement}
        {...props}
      />
      {error && (
        <p className="select__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="select__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

export default Select;
