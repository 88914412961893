import { PURGE } from "redux-persist";

const initialState = {
  therapists: [],
};

const therapists = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_THERAPISTS":
      return {
        ...state,
        therapists: payload,
      };
    case "UPDATE_SUBSCRIBER":
      return {
        ...state,
        therapists: state.therapists.map((t) =>
          t.uuid === payload.therapistId
            ? {
                ...t,
                ...payload,
              }
            : t,
        ),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default therapists;
