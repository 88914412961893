import { useDispatch } from "react-redux";
import setAuthenticatedUser from "store/actions/auth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useFetchAssignments,
  useFetchUsers,
  useFetchSubmissions,
  useSendCode,
} from "utils/hooks";

const useLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fetchAllAssignments } = useFetchAssignments();
  const { fetchAllUsers } = useFetchUsers();
  const { fetchAllSubmissions } = useFetchSubmissions();
  const { sendCode } = useSendCode();
  const submitLogin = async (args) => {
    if (!args) {
      return null;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              adminLogin(
                email: "${args.email}",
                password: "${args.password}",
              ) {
                userId
                firstName
                lastName
                email
                expiresIn
                type
                verified
                is2FAEnabled
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (res.status !== 200 && res.status !== 201) {
        toast.error(`Hmm, something went wrong.`);
        throw new Error("Failed!");
      }

      const resData = await res.json();
      const { adminLogin } = resData.data;

      if (adminLogin.is2FAEnabled) {
        await sendCode(adminLogin.email);
        return history.push("/login/2fa", adminLogin);
      }

      dispatch(setAuthenticatedUser(adminLogin));
      // Fetching some data on login
      fetchAllUsers();
      fetchAllSubmissions();
      fetchAllAssignments();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    submitLogin,
  };
};

export default useLogin;
