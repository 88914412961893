import "./StatsCard.styles.scss";
import { useHistory } from "react-router-dom";
import { Icon } from "components";
import ArrowRight from "assets/icons/arrow-right.svg";

const StatsCard = ({ data, title, color, icon }) => {
  const history = useHistory();
  const handleStatsCardNavigation = () => {
    history.push(`/${title.replace(/\s+/g, "-").toLowerCase()}`);
  };

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={handleStatsCardNavigation}
      onKeyDown={null}
      aria-label="navigate to stats page"
      className={`stats-card ${color}`}
    >
      <div className="stats-card_right">
        <div className="stats-card_icon--wrapper">
          <div className="stats-card_icon">
            <Icon src={icon} alt={`${title} icon`} />
          </div>
        </div>
        <div className="stats-card_data">
          <p className="stats-card_count">{data.length}</p>
          <p className="stats-card_title">
            {data.length > 1 || data.length === 0 ? title : title.slice(0, -1)}
          </p>
        </div>
      </div>
      <div id="statsCardRight">
        <div className="stats-car__nav-arrow">
          <Icon src={ArrowRight} alt={`go to ${title} icon`} />
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
