/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./Modal.styles.scss";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";

const Modal = ({ isOpen, onClose, ...props }) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isOpen}
      timeout={300}
      classNames="animated-modal"
    >
      <div ref={nodeRef} className="local-modal-wrapper">
        <div
          className="local-modal-overlay"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        />
        <div className="local-modal-inner">{props.children}</div>
      </div>
    </CSSTransition>
  );
};

export default Modal;
