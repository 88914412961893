/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import "./SideNav.styles.scss";
import LogoIcon from "assets/icons/castle.svg";
import SignOutIcon from "assets/icons/sign-out.svg";
import Icon from "components/utility/Icon";
import SideNavItem from "./components/SideNavItem";
import { BRAND_NAME } from "utils/constants";
import { navItems } from "../constants/navItems";
import { useLogout } from "utils/hooks";

const SideNav = () => {
  const { logOut } = useLogout();
  return (
    <div className="side-nav side-nav__expanded">
      <div className="side-nav__top__wrapper">
        <div className="side-nav__top">
          <div className="side-nav__logo">
            <Icon src={LogoIcon} />
          </div>
          <div className="side-nav__brand">{BRAND_NAME}</div>
        </div>
        <div className="side-nav__menu__wrapper">
          <ul className="side-nav__menu">
            {navItems.map((sideNavItem, i) => {
              return <SideNavItem key={i} expanded item={sideNavItem} />;
            })}
          </ul>
        </div>
      </div>

      <ul className="side-nav__menu">
        <li
          onClick={() => logOut()}
          onKeyDown={() => logOut()}
          className="side-nav-item side-nav-item__expanded"
          name="logout"
          id="logout"
          data-tip
          data-for="logout-tip"
        >
          <div className="side-nav-item__icon">
            <Icon src={SignOutIcon} />
          </div>
          <div className="side-nav-item__label">Log out</div>
        </li>
      </ul>
    </div>
  );
};

export default SideNav;
