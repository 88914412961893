export const fetchResourcesDataAction = (resourcesData) => {
  return {
    type: "FETCH_RESOURCES_DATA",
    payload: resourcesData,
  };
};

export const fetchLibraryResourcesAction = (resources) => {
  return {
    type: "FETCH_LIBRARY_RESOURCES",
    payload: resources,
  };
};

export const addLibraryResourceAction = (resource) => {
  return {
    type: "ADD_LIBRARY_RESOURCE",
    payload: resource,
  };
};

export const updateLibraryResourceAction = (resource) => {
  return {
    type: "UPDATE_LIBRARY_RESOURCE",
    payload: resource,
  };
};

export const deleteLibraryResourceAction = (resourceId) => {
  return {
    type: "DELETE_LIBRARY_RESOURCE",
    payload: resourceId,
  };
};
