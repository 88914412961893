import { useSelector } from "react-redux";

const useGetAssignments = () => {
  const assignments = useSelector(
    (state) => state.assignments && state.assignments,
  );

  if (!assignments) {
    return null;
  }

  return {
    assignments: {
      total: assignments.assignments.total,
      totalLive: assignments.assignments.totalLive,
      averageDuration: assignments.assignments.averageDuration,
      openEndedCount: assignments.assignments.openEndedCount,
      averagePerPatient: assignments.assignments.averagePerPatient,
      averagePerTherapist: assignments.assignments.averagePerTherapist,
    },
  };
};

export default useGetAssignments;
