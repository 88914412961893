import "./TableHead.styles.scss";
import PropTypes from "prop-types";
import Sort from "assets/icons/sort.svg";
import SortUp from "assets/icons/sort-up.svg";
import SortDown from "assets/icons/sort-down.svg";
import Icon from "components/utility/Icon";

const TableHead = ({ headerGroups }) => {
  return (
    <thead className="data-table-head">
      {
        // Loop over the header rows
        headerGroups.map((headerGroup) => (
          // Apply the header row props
          <tr
            className="data-table-header-row"
            {...headerGroup.getHeaderGroupProps()}
          >
            {
              // Loop over the headers in each row
              headerGroup.headers.map((column) => (
                // Apply the header cell props
                <th
                  className="data-table-column-header"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  {!column.disableSortBy && (
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <div className="data-column-sort-icon">
                            <Icon src={SortDown} />
                          </div>
                        ) : (
                          <div className="data-column-sort-icon">
                            <Icon src={SortUp} />
                          </div>
                        )
                      ) : (
                        <div className="data-column-sort-icon data-header-faded">
                          <Icon src={Sort} />
                        </div>
                      )}
                    </span>
                  )}
                </th>
              ))
            }
          </tr>
        ))
      }
    </thead>
  );
};

TableHead.propTypes = {
  headerGroups: PropTypes.array.isRequired,
};

export default TableHead;
