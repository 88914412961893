import { useSelector } from "react-redux";

const useGetUserLogs = () => {
  const userLogs = useSelector((state) => state.userLogs && state.userLogs);

  if (!userLogs) {
    return [];
  }
  return {
    userLogs: userLogs.userLogs,
  };
};

export default useGetUserLogs;
