import "./AuthenticationLayout.styles.scss";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { Animate, Logo } from "components";
import useAuthRedirect from "./utils/useAuthRedirect";

const AuthenticationLayout = (props) => {
  const { redirect } = useAuthRedirect();
  useEffect(() => {
    redirect();
  }, [redirect]);

  return (
    <>
      <div className="flex authentication-layout-wrapper">
        <section className="section authentication-card">
          <div className="authentication-card__logo">
            <Animate animation="fadeInLeft">
              <Logo />
            </Animate>
          </div>
          {props.children}
        </section>
      </div>
    </>
  );
};

AuthenticationLayout.propTypes = {
  children: PropTypes.node,
};

export default AuthenticationLayout;
