import "./PageEmptyState.styles.scss";

const PageEmptyState = ({ user }) => {
  return (
    <div className="page-empty-state">
      <h2>Hello{user ? `, <b>${user.firstName}.</b>` : "!"} 👋</h2>
      <div className="page-empty-state__body">
        <p>
          There isn't any data here. That means you're either at the start of an
          exciting new beginning, or something is terribly wrong. Check with
          your team to find out.
        </p>
      </div>
    </div>
  );
};

export default PageEmptyState;
