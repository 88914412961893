import "./Button.styles.scss";
import PropTypes from "prop-types";
import clsx from "clsx";
import Loader from "assets/icons/spinner-third.svg";
import { Icon } from "components";

const Button = ({
  value,
  name,
  type,
  disabled,
  fullWidth,
  isLoading,
  extraClass,
  pill,
  onClick,
  icon,
  size,
}) => {
  const onClickHandler = (e) => {
    onClick && e.preventDefault();
    onClick && e.stopPropagation();
    onClick && onClick();
  };

  return (
    <button
      onClick={onClickHandler}
      name={name}
      type={type}
      disabled={disabled || isLoading}
      className={clsx(
        "button",
        "transition",
        fullWidth && "full-width",
        extraClass,
        pill && "pill",
        size,
      )}
    >
      {isLoading ? (
        <div className="button__loader">
          <Icon src={Loader} />
        </div>
      ) : (
        ""
      )}
      {icon && !isLoading ? (
        <div className="transition button__icon">
          <Icon src={icon} />
        </div>
      ) : (
        ""
      )}
      {value}
    </button>
  );
};

Button.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  extraClass: PropTypes.string,
  pill: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  size: PropTypes.string,
};

Button.defaultProps = {
  type: "button",
  disabled: false,
  fullWidth: false,
  isLoading: false,
  extraClass: "",
  pill: false,
};

export default Button;
