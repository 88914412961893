import { Route, Redirect } from "react-router-dom";
import { useCurrentUser } from "utils/hooks";

const AdminRoute = ({ component: Component, ...rest }) => {
  const { isAdmin } = useCurrentUser();
  return (
    <>
      {isAdmin ? (
        <Route {...rest}>{rest.children}</Route>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )}
    </>
  );
};

export default AdminRoute;
