import "./UserStatsCard.styles.scss";
import { Icon, ImageSeparator } from "components";
import ArrowRight from "assets/icons/arrow-right.svg";
import { useGetUsers } from "utils/hooks";
import UsersIcon from "assets/icons/users.svg";
import TherapistsIcon from "assets/icons/user-tie.svg";
import PatientsIcon from "assets/icons/users-medical.svg";

const UserStatsCard = ({ data, title }) => {
  const {
    allTherapists,
    unverifiedTherapists,
    verifiedTherapists,
    verifiedPatients,
    unverifiedPatients,
    allPatients,
    temporaryPatients,
  } = useGetUsers({});
  const therapistTitle = "Therapists";
  const patientsTitle = "Patients";

  return (
    <div className="user-stats-card">
      <div className="user-stats-card_section">
        <div className="user-stats-card_section-title">
          <div className="user-stats-card_icon--wrapper">
            <div className="user-stats-card_icon">
              <Icon src={UsersIcon} alt={`${title} icon`} />
            </div>
          </div>
          <div className="user-stats-card_main-title">User Stats</div>
        </div>
        <div className="user-stats-card_data">
          <p className="user-stats-card_count">{data.length}</p>
          <p className="user-stats-card_title">
            {data.length > 1 || data.length === 0 ? title : title.slice(0, -1)}
          </p>
        </div>
      </div>
      <ImageSeparator />
      <div className="user-stats-card_table">
        <div className="user-stats-card_intro">
          <div className="user-stats-card_icon--wrapper">
            <div className="user-stats-card_icon">
              <Icon src={TherapistsIcon} alt={`${therapistTitle} icon`} />
            </div>
          </div>
          <p className="user-stats-card_section-title">{therapistTitle}</p>
        </div>
        <div className="user-stats-card_column">
          <p className="user-stats-card_count">{allTherapists.length}</p>
          <p className="user-stats-card_title">total</p>
        </div>
        <div className="user-stats-card_column">
          <p className="user-stats-card_count">{verifiedTherapists.length}</p>
          <p className="user-stats-card_title">verified</p>
        </div>
        <div className="user-stats-card_column">
          <p className="user-stats-card_count">{unverifiedTherapists.length}</p>
          <p className="user-stats-card_title">unverified</p>
        </div>
      </div>
      <ImageSeparator />
      <div className="user-stats-card_table">
        <div className="user-stats-card_intro">
          <div className="user-stats-card_icon--wrapper">
            <div className="user-stats-card_icon">
              <Icon src={PatientsIcon} alt={`${patientsTitle} icon`} />
            </div>
          </div>
          <p className="user-stats-card_section-title">{patientsTitle}</p>
        </div>
        <div className="user-stats-card_column">
          <p className="user-stats-card_count">{allPatients.length}</p>
          <p className="user-stats-card_title">total</p>
        </div>
        <div className="user-stats-card_column">
          <p className="user-stats-card_count">{verifiedPatients.length}</p>
          <p className="user-stats-card_title">verified</p>
        </div>
        <div className="user-stats-card_column">
          <p className="user-stats-card_count">{unverifiedPatients.length}</p>
          <p className="user-stats-card_title">unverified</p>
        </div>
        <div className="user-stats-card_column">
          <p className="user-stats-card_count">{temporaryPatients.length}</p>
          <p className="user-stats-card_title">Pending Invite</p>
        </div>
      </div>
      <div id="statsCardRight">
        <div className="user-stats-card__nav-arrow">
          <Icon src={ArrowRight} alt={`go to ${title} icon`} />
        </div>
      </div>
    </div>
  );
};

export default UserStatsCard;
