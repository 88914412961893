import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";

const useResendCode = () => {
  const { logOut } = useLogout();

  const resendCode = async (email) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_REST_API_URL}/2fa/resend-code`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        },
      );

      const resData = await res.json();

      if (res.ok) {
        toast(resData.message);
      }

      if (!res.ok) {
        if (resData.message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(resData.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    resendCode,
  };
};

export default useResendCode;
