const useQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const hasUrlParams = (params) => {
    return urlParams.get(params);
  };

  return {
    hasUrlParams,
  };
};

export default useQueryParams;
