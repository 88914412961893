export const fetchSubscriptionsAction = (subscriptions) => {
  return {
    type: "FETCH_SUBSCRIPTIONS",
    payload: subscriptions,
  };
};

export const updateSubscriptionAction = (subscription) => {
  return {
    type: "UPDATE_SUBSCRIPTION",
    payload: subscription,
  };
};

export const updateSubscriberAction = (subscriber) => {
  return {
    type: "UPDATE_SUBSCRIBER",
    payload: subscriber,
  };
};

export const createSubscriptionAction = (subscription) => {
  return {
    type: "CREATE_SUBSCRIBTION",
    payload: subscription,
  };
};
