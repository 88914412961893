import { PURGE } from "redux-persist";

const initialState = {
  resourcesData: {},
  resources: [],
};

const resources = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_RESOURCES_DATA":
      return {
        ...state,
        resourcesData: payload,
      };
    case "FETCH_LIBRARY_RESOURCES":
      return {
        ...state,
        resources: payload,
      };
    case "ADD_LIBRARY_RESOURCE":
      return {
        ...state,
        resources: [...state.resources, payload],
      };
    case "UPDATE_LIBRARY_RESOURCE":
      return {
        ...state,
        resources: state.resources.map((resource) =>
          resource.uuid === payload.uuid ? payload : resource,
        ),
      };
    case "DELETE_LIBRARY_RESOURCE":
      return {
        ...state,
        resources: state.resources.filter((resource) => resource !== payload),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default resources;
