import { useSelector } from "react-redux";

const useGetPermissions = () => {
  const permissions = useSelector(
    (state) => state.permissions && state.permissions.permissions,
  );

  if (!permissions) {
    return { permissions: [], categories: [] };
  }

  // Extract unique categories from the permissions
  const uniqueCategories = [
    ...new Set(permissions.map((perm) => perm.category)),
  ];

  const categories = uniqueCategories.map((category) => ({
    label: category,
    value: category,
  }));

  return {
    permissions: permissions,
    permissionCategories: categories,
  };
};

export default useGetPermissions;
