import PropTypes from "prop-types";
import SVG from "react-inlinesvg";

const Icon = ({ src }) => {
  return <SVG src={src} cacheRequests />;
};

Icon.propTypes = {
  src: PropTypes.string,
};

export default Icon;
