import { useSelector } from "react-redux";

const useGetTherapists = ({ uuid }) => {
  const therapists = useSelector(
    (state) => state.therapists && state.therapists,
  );

  if (!therapists) {
    return [];
  }

  const therapist = therapists.therapists.find((t) => t.uuid === uuid);

  return {
    therapists: therapists.therapists,
    therapist: therapist,
  };
};

export default useGetTherapists;
