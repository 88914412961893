import "./AssignmentsStatsCard.styles.scss";
import { Icon, ImageSeparator } from "components";
import ArrowRight from "assets/icons/arrow-right.svg";
import AssignmentIcon from "assets/icons/file-spreadsheet.svg";

const AssignmentsStatsCard = ({ data, title }) => {
  const {
    total,
    totalLive,
    openEndedCount,
    averagePerPatient,
    averagePerTherapist,
    averageDuration,
  } = data;

  return (
    <div className="assignments-stats-card">
      <div className="assignments-stats-card_section">
        <div className="assignments-stats-card_section-title">
          <div className="assignments-stats-card_icon--wrapper">
            <div className="assignments-stats-card_icon">
              <Icon src={AssignmentIcon} alt={`${title} icon`} />
            </div>
          </div>
          <div className="assignments-stats-card_main-title">
            Assignment Stats
          </div>
        </div>
        <div className="assignments-stats-card_data">
          <p className="assignments-stats-card_count">{totalLive}</p>
          <p className="assignments-stats-card_title">
            {total > 1 || total === 0 ? title : title.slice(0, -1)}
          </p>
        </div>
      </div>
      <ImageSeparator />
      <div className="assignments-stats-card_table">
        <div className="assignments-stats-card_column">
          <p className="assignments-stats-card_count">{averageDuration} days</p>
          <p className="assignments-stats-card_title">Avg Duration</p>
        </div>
        <div className="assignments-stats-card_column">
          <p className="assignments-stats-card_count">{openEndedCount}</p>
          <p className="assignments-stats-card_title">Open Ended</p>
        </div>
        <div className="assignments-stats-card_column">
          <p className="assignments-stats-card_count">
            {averagePerPatient?.substring(0, 3)}
          </p>
          <p className="assignments-stats-card_title">Avg per Patient</p>
        </div>
        <div className="assignments-stats-card_column">
          <p className="assignments-stats-card_count">
            {averagePerTherapist?.substring(0, 3)}
          </p>
          <p className="assignments-stats-card_title">Avg per Therapist</p>
        </div>
        <div className="assignments-stats-card_column">
          <p className="assignments-stats-card_count">{total - totalLive}</p>
          <p className="assignments-stats-card_title">Deleted</p>
        </div>
      </div>
      <div id="statsCardRight">
        <div className="assignments-stats-card__nav-arrow">
          <Icon src={ArrowRight} alt={`go to ${title} icon`} />
        </div>
      </div>
    </div>
  );
};

export default AssignmentsStatsCard;
