import { PURGE } from "redux-persist";

const initialState = {
  assignments: {},
};

const assignments = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_ASSIGNMENTS":
      return {
        ...state,
        assignments: payload,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default assignments;
