import "./TableBody.styles.scss";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const TableBody = ({ page, prepareRow, rowProps, ...props }) => {
  const rowRefs = useRef([]);
  const [currentRowRefs, setCurrentRowRefs] = useState([]);
  const setRowProps = (row, index) =>
    rowProps
      ? { ...row.getRowProps(rowProps(row, currentRowRefs[index])) }
      : { ...row.getRowProps() };
  useEffect(() => {
    setCurrentRowRefs(rowRefs.current);
  }, [rowRefs, currentRowRefs]);
  return (
    <tbody {...props}>
      {
        // Loop over the table rows
        page.map((row, i) => {
          // Prepare the row for display
          prepareRow(row);
          return (
            // Apply the row props
            <tr
              className="data-table-row"
              ref={(ref) => (rowRefs.current[i] = ref)}
              {...setRowProps(row, i)}
              id={`rowIndex-${i}`}
              key={`rowIndex-${i}`}
            >
              {
                // Loop over the rows cells
                row.cells.map((cell) => {
                  // Apply the cell props
                  return (
                    <td className="data-table-cell" {...cell.getCellProps()}>
                      {
                        // Render the cell contents
                        cell.render("Cell")
                      }
                    </td>
                  );
                })
              }
            </tr>
          );
        })
      }
    </tbody>
  );
};

TableBody.propTypes = {
  page: PropTypes.array.isRequired,
};

export default TableBody;
