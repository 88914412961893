import "./RangeField.styles.scss";
import useRangeField from "./RangeField.hooks";
import PropTypes from "prop-types";
import clsx from "clsx";

const RangeField = ({
  label,
  error,
  hint,
  min,
  max,
  id,
  onChange,
  value,
  ...props
}) => {
  const { currentValue, handleValueChange } = useRangeField({
    value,
    id,
    min,
    max,
  });
  return (
    <div className="range-field">
      <label className="range-field__label">{label}</label>
      <div className="range-field__input-wrapper">
        <div className={`range-field__bubble bubble-${id}`}>
          {value || currentValue}
        </div>
        <input
          aria-label={label}
          className={clsx("range-field__field", error && "error")}
          min={min}
          max={max}
          value={value || currentValue}
          {...props}
          onChange={(e) => {
            onChange(e.target.value);
            handleValueChange(e.target.value);
          }}
        />
      </div>
      {error && (
        <p className="range-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="range-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

RangeField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
};

RangeField.defaultProps = {
  type: "range",
  min: "0",
  max: "100",
};

export default RangeField;
