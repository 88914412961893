import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";
import { fetchRolesAction } from "store/actions/roles";
import { useCallback } from "react";

const useFetchRoles = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();

  const fetchAllRoles = useCallback(() => {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
              query {
                getAllRolesWithPermissions {
                  uuid
                  name
                  description
                  permissions {
                    uuid
                    name
                    category
                    label
                    description
                    associated
                  }
                  createdAt
                  updatedAt
                }
              }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes, please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              reject(new Error("Failed!"));
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`,
            );
            reject(new Error(resData.errors[0].message));
          } else {
            // Process the response to ensure the data is in the expected format
            const rolesWithPermissions =
              resData.data.getAllRolesWithPermissions.map((role) => ({
                ...role,
                permissions: role.permissions.map((permission) => ({
                  ...permission,
                  associated: !!permission.associated, // Ensure associated is a boolean
                })),
              }));

            dispatch(fetchRolesAction(rolesWithPermissions));
            resolve(rolesWithPermissions);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }, [dispatch, logOut]);

  return {
    fetchAllRoles,
  };
};

export default useFetchRoles;
