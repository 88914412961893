import "./Input.styles.scss";
import PropTypes from "prop-types";
import clsx from "clsx";

const Input = ({
  label,
  error,
  forwardRef,
  hint,
  optionalField,
  extraClass,
  inputExtraClass,
  ...props
}) => {
  return (
    <div className={clsx("input", extraClass)}>
      <label className="input__label">
        {label}
        {optionalField && (
          <span className="input__optional-label"> (optional)</span>
        )}
      </label>
      <input
        aria-label={label}
        className={clsx("input__field", error && "error", inputExtraClass)}
        ref={forwardRef}
        {...props}
      />
      {error && (
        <p className="input__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="input__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  hint: PropTypes.string,
  forwardRef: PropTypes.func,
  optionalField: PropTypes.bool,
};

Input.defaultProps = {
  type: "text",
  optionalField: false,
};

export default Input;
