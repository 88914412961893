import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addLibraryResourceAction } from "store/actions/resources";
import {
  useLogout,
  useFetchLibraryResources,
  useGetLibraryResources,
} from "utils/hooks";
import { formatInput } from "utils/helpers";

const useAddLibraryResource = () => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchLibraryResources } = useFetchLibraryResources();
  const { resources } = useGetLibraryResources();

  const submitAddLibraryResource = (args) => {
    if (!args) {
      return null;
    }

    const existingUrl = resources.find(
      (resource) => resource.resourceUrl === args.resourceUrl,
    );

    if (existingUrl) {
      toast.warn(`A resource with this link already exists in the library :)`);
      return null;
    }

    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
              mutation {
                addAdminLibraryResource(resource: {
                  resourceUrl: "${formatInput(args.resourceUrl)}",
                  imageUrl: "${formatInput(args.imageUrl)}",
                  title: "${formatInput(args.title)}",
                  description: "${formatInput(args.description)}",
                }) {
                  resourceUrl
                  title
                  description
                  imageUrl
                  uuid
                  createdAt
                }
              }
            `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(
                `${error.errors[0].message || "Hmm, something went wrong."}`,
              );
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`,
            );
            return true;
          } else {
            fetchLibraryResources();
            toast("Awesome! Resource Added!");
            setIsSubmitSuccessful(true);
            return dispatch(
              addLibraryResourceAction(resData.data.addAdminLibraryResource),
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    submitAddLibraryResource,
    isSubmitSuccessful,
  };
};

export default useAddLibraryResource;
