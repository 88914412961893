import { useSelector } from "react-redux";

const useGetPlans = () => {
  const plans = useSelector((state) => state.plans && state.plans.plans);

  if (!plans) {
    return null;
  }

  return {
    plans: plans,
  };
};

export default useGetPlans;
