/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import "./MobileBottomNav.styles.scss";
import MobileBottomNavItem from "./components/MobileBottomNavItem";
import { navItems } from "../constants/navItems";

const MobileBottomNav = () => {
  return (
    <div className="mobile-bottom-nav">
      <div className="mobile-bottom-nav__menu__wrapper">
        <ul className="mobile-bottom-nav__menu">
          {navItems.map((mobileBottomNavItem, i) => {
            return <MobileBottomNavItem key={i} item={mobileBottomNavItem} />;
          })}
        </ul>
      </div>
    </div>
  );
};

export default MobileBottomNav;
