import { useSelector } from "react-redux";

const useCurrentUser = () => {
  const userRaw = useSelector(
    (state) => state.auth && state.auth.user && state.auth.user,
  );

  if (!userRaw) {
    return false;
  }

  const currentTime = new Date().getTime() / 1000;
  const isAuthenticated = currentTime < userRaw?.expiresIn;
  const isVerified = userRaw.verified;

  const user = {
    fullName: `${userRaw.firstName} ${userRaw.lastName}`,
    firstName: userRaw.firstName,
    lastName: userRaw.lastName,
    email: userRaw.email,
    id: userRaw.userId,
  };

  const isAdmin = userRaw.type === "admin";

  return {
    isVerified,
    isAuthenticated,
    isAdmin,
    user,
  };
};

export default useCurrentUser;
