import "./PageActionBar.styles.scss";

const PageActionBar = (props) => {
  return (
    <div className="flex justify-between align-center page-action-bar">
      {props.children}
    </div>
  );
};

export default PageActionBar;
