import { useEffect, useState } from "react";

const useRangeField = ({ value, id, min, max }) => {
  const [currentValue, setCurrentValue] = useState(value || max / 2);

  const handleValueChange = (value) => {
    setCurrentValue(value);
  };

  useEffect(() => {
    const bubbleElement = document.querySelector(`.bubble-${id}`);
    const locationValue = Number(((currentValue - min) * 100) / (max - min));
    if (bubbleElement) {
      bubbleElement.style.left = `calc(${locationValue}% + (${
        8 - locationValue * 0.15
      }px))`;
    }
  });

  return {
    currentValue,
    handleValueChange,
  };
};

export default useRangeField;
