export const fetchPlansAction = (plans) => {
  return {
    type: "FETCH_PLANS",
    payload: plans,
  };
};

export const createPlanAction = (plan) => {
  return {
    type: "CREATE_PLAN",
    payload: plan,
  };
};

export const updatePlanAction = (plan) => {
  return {
    type: "UPDATE_PLAN",
    payload: plan,
  };
};

export const deletePlanAction = (plan) => {
  return {
    type: "DELETE_PLAN",
    payload: plan,
  };
};
