import "./Textarea.styles.scss";
import { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const Textarea = ({
  label,
  error,
  forwardRef,
  hint,
  rows,
  optionalField,
  ...props
}) => {
  const [charCount, setCharCount] = useState(props.defaultValue?.length || 0);

  const handleTextChange = (e) => {
    setCharCount(e.target.value.length);
  };

  return (
    <div className="textarea">
      <label className="textarea__label">
        {label}
        {optionalField && (
          <span className="textarea__optional-label"> (optional)</span>
        )}
      </label>
      <textarea
        aria-label={label}
        className={clsx("textarea__field", error && "error")}
        ref={forwardRef}
        rows={Number(rows)}
        onChange={(e) => {
          handleTextChange(e);
        }}
        {...props}
      />
      {error && (
        <p className="textarea__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="textarea__hint">
          <small>{hint}</small>
        </p>
      )}
      {props.maxLength && (
        <p
          className={clsx(
            "textarea__hint",
            charCount === props.maxLength && "textarea__error",
          )}
        >
          <small>
            {charCount}/{props.maxLength}
          </small>
        </p>
      )}
    </div>
  );
};

Textarea.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  forwardRef: PropTypes.func,
  hint: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
  defaultValue: PropTypes.string,
};

Textarea.defaultProps = {
  optionalField: false,
};

export default Textarea;
