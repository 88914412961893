import { persistor } from "store";
import { useHistory } from "react-router-dom";

const useLogout = () => {
  const history = useHistory();

  const logOut = () => {
    localStorage.removeItem("expiresIn");
    persistor.purge().then(() => history.push("/login"));
  };

  return {
    logOut,
  };
};

export default useLogout;
