import "react-datepicker/dist/react-datepicker.css";
import "./TimeField.styles.scss";
import { useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import clsx from "clsx";

const TimeField = ({
  name,
  label,
  error,
  forwardRef,
  hint,
  placeholder,
  ...props
}) => {
  const [selectedTime, setSelectedTime] = useState(new Date());
  return (
    <div className="time-field">
      <label className="time-field__label">{label}</label>
      <DatePicker
        name={name}
        aria-label={label}
        className={clsx("time-field__field", error && "error")}
        ref={forwardRef}
        selected={selectedTime}
        onChange={(date) => setSelectedTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        placeholderText={placeholder}
        {...props}
      />
      {error && (
        <p className="time-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="time-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

TimeField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

TimeField.defaultProps = {
  type: "time",
};

export default TimeField;
