/* eslint-disable jsx-a11y/no-onchange */
import PropTypes from "prop-types";
import { useTable, useSortBy, usePagination } from "react-table";

import TableWrapper from "./components/TableWrapper";
import TableHead from "./components/TableHead";
import TableBody from "./components/TableBody";
import EmptyTableBody from "./components/EmptyTableBody";
import TablePagination from "./components/TablePagination";

const DataTable = ({ data, columns, dataType, rowProps, id }) => {
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 50 } },
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
    getRowProps = rowProps,
  } = tableInstance;

  return (
    <>
      <TableWrapper {...getTableProps()} id={id}>
        <TableHead headerGroups={headerGroups} />
        {/* Apply the table body props */}
        {data.length ? (
          <TableBody
            page={page}
            prepareRow={prepareRow}
            {...getTableBodyProps()}
            rowProps={getRowProps}
          />
        ) : (
          <EmptyTableBody dataType={dataType} />
        )}
      </TableWrapper>
      {data.length > 10 ? (
        <TablePagination
          goToPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      ) : null}
    </>
  );
};

DataTable.propTypes = {
  dataType: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
};

export default DataTable;
