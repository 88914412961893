import { PURGE } from "redux-persist";

const initialState = {
  notes: {},
};

const notes = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_NOTES":
      return {
        ...state,
        notes: payload,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default notes;
