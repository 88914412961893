import "react-datepicker/dist/react-datepicker.css";
import "./DateField.styles.scss";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import clsx from "clsx";

const DateField = ({
  label,
  error,
  selected,
  onChange,
  hint,
  placeholder,
  showMonthDropdown,
  showYearDropdown,
  ...props
}) => {
  return (
    <div className="date-field">
      <label className="date-field__label">{label}</label>
      <DatePicker
        aria-label={label}
        className={clsx("date-field__field", error && "error")}
        selected={selected}
        onChange={onChange}
        peekNextMonth
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode="select"
        placeholderText={placeholder}
        {...props}
      />
      {error && (
        <p className="date-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="date-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

DateField.defaultProps = {
  type: "date",
};

export default DateField;
