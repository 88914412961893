import { PURGE } from "redux-persist";

const initialState = {
  patients: [],
};

const patients = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PATIENTS":
      return {
        ...state,
        patients: payload,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default patients;
