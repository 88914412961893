import { PURGE } from "redux-persist";

const initialState = {
  userLogs: [],
};

const userLogs = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_USER_LOGS":
      return {
        ...state,
        userLogs: [...payload],
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
export default userLogs;
