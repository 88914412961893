import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useFetchLibraryResources } from "utils/hooks";

const useGetLibraryResources = () => {
  const { fetchLibraryResources } = useFetchLibraryResources();

  const resources = useSelector(
    (state) => state.resources.resources && state.resources.resources,
  );

  useEffect(() => {
    if (resources?.length === 0) {
      fetchLibraryResources();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    resources: resources.sort((a, b) => b.createdAt - a.createdAt),
  };
};

export default useGetLibraryResources;
