export const fetchAllUsersAction = (users) => {
  return {
    type: "FETCH_ALL_USERS",
    payload: users,
  };
};

export const fetchUserLogsAction = (userLogs) => {
  return {
    type: "FETCH_USER_LOGS",
    payload: userLogs,
  };
};
