import "./StringField.styles.scss";
import PropTypes from "prop-types";
import clsx from "clsx";

const StringField = ({ label, error, forwardRef, hint, ...props }) => {
  return (
    <div className="string-field">
      <label className="string-field__label">{label}</label>
      <input
        aria-label={label}
        className={clsx("string-field__field", error && "error")}
        ref={forwardRef}
        {...props}
      />
      {error && (
        <p className="string-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="string-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

StringField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

StringField.defaultProps = {
  type: "text",
};

export default StringField;
