import { PURGE } from "redux-persist";

const initialState = {
  submissions: [],
};

const submissions = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_SUBMISSIONS":
      return {
        ...state,
        submissions: payload,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default submissions;
