import { useEffect, useState } from "react";
import { BREAKPOINTS } from "utils/constants";

const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(width <= BREAKPOINTS.viewport7);
  const [isDesktop, setIsDesktop] = useState(width > BREAKPOINTS.viewport7);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setIsMobile(window.innerWidth <= BREAKPOINTS.viewport7);
      setIsDesktop(window.innerWidth > BREAKPOINTS.viewport7);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [width]);

  return {
    width,
    isMobile,
    isDesktop,
  };
};

export default useViewport;
