import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";
import { updateRolesAction } from "store/actions/roles";

const useBulkUpdateRoles = () => {
  const [isBulkUpdateSuccessful, setIsBulkUpdateSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();

  const submitBulkUpdateRoles = (editedPermissions) => {
    const rolesPayload = Object.entries(editedPermissions).map(
      ([roleUuid, permissions]) => ({
        uuid: roleUuid,
        permissions,
      }),
    );

    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              updateRoles(roles: ${JSON.stringify(rolesPayload)}) {
                uuid
                name
                permissions {
                  uuid
                  label
                }
                createdAt
                updatedAt
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes, please log in again.`);
              logOut();
            } else {
              toast.error(
                `${error.errors[0].message || "Hmm, something went wrong."}`,
              );
              reject(new Error("Failed!"));
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`,
            );
            reject(resData.errors[0].message);
          } else {
            toast("Roles updated successfully!");
            setIsBulkUpdateSuccessful(true);
            dispatch(updateRolesAction(resData.data.updateRoles));
            resolve();
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  };

  return {
    submitBulkUpdateRoles,
    isBulkUpdateSuccessful,
  };
};

export default useBulkUpdateRoles;
