/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-onchange */
import "./TablePagination.styles.scss";
import clsx from "clsx";
import SkipToEnd from "assets/icons/chevron-double-right.svg";
import SkipToStart from "assets/icons/chevron-double-left.svg";
import NextPage from "assets/icons/chevron-right.svg";
import PreviousPage from "assets/icons/chevron-left.svg";
import { Icon } from "components";

const TablePagination = ({
  goToPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageOptions,
  pageIndex,
  pageSize,
  setPageSize,
}) => {
  return (
    <div className="data-table__pagination">
      <div className="data-table__pagination-buttons">
        <div
          className={clsx(
            "data-table__pagination-buttons--button",
            !canPreviousPage && "pagination-disabled",
          )}
          onClick={() => canPreviousPage && goToPage(0)}
        >
          <Icon src={SkipToStart} />
        </div>
        <div
          className={clsx(
            "data-table__pagination-buttons--button",
            !canPreviousPage && "pagination-disabled",
          )}
          onClick={() => canPreviousPage && previousPage()}
        >
          <Icon src={PreviousPage} />
        </div>
        <div
          className={clsx(
            "data-table__pagination-buttons--button",
            !canNextPage && "pagination-disabled",
          )}
          onClick={() => canNextPage && nextPage()}
        >
          <Icon src={NextPage} />
        </div>
        <div
          className={clsx(
            "data-table__pagination-buttons--button",
            !canNextPage && "pagination-disabled",
          )}
          onClick={() => canNextPage && goToPage(pageCount - 1)}
        >
          <Icon src={SkipToEnd} />
        </div>
      </div>
      <div className="data-table__pagination-page-count">
        Page <strong>{pageIndex + 1}</strong> of{" "}
        <strong>{pageOptions.length}</strong>
      </div>
      <div className="data-table__pagination-page-separator">|</div>
      <div className="data-table__pagination-page-input">
        Go to page:
        <input
          className="data-table__pagination-page-input-field"
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            goToPage(page);
          }}
        />
      </div>
      <div className="data-table__pagination-page-separator">|</div>
      <div className="data-table__pagination-count-select">
        <select
          className="data-table__pagination-count-select-field"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TablePagination;
