import { PURGE } from "redux-persist";

const initialState = {
  showConfettiCanvas: false,
};

const uiState = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SHOW_CONFETTI_CANVAS":
      return {
        ...state,
        showConfettiCanvas: payload,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default uiState;
