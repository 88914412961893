import { useCurrentUser } from "utils/hooks";
import { useHistory } from "react-router-dom";

const useGuestRedirect = () => {
  const { isAuthenticated } = useCurrentUser();
  const history = useHistory();

  const redirect = () => {
    if (!isAuthenticated) {
      history.push("/login");
    }
  };

  return {
    redirect,
  };
};

export default useGuestRedirect;
